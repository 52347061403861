<template>
  <form v-if="!isSent" class="form form-client-application">
    <div v-if="data?.mainField?.length" class="form-client-application__main row">
      <div
        v-for="item in data?.mainField"
        :key="item?.name"
        class="col-12 col-md-6 col-lg-12 col-xxl-6"
      >
        <ElementForm
          :data="item"
          :activeValidate="activeValidate"
          @change="handleChange($event, item?.name)"
          @update-validate="setValidate($event, item?.name)"
        />
      </div>
    </div>
    <div v-if="data?.request" class="form-client-application__request row">
      <div class="col-12">
        <ElementForm
          :data="data?.request"
          :activeValidate="activeValidate"
          @change="handleChange($event, 'request')"
          @update-validate="setValidate($event, 'request')"
        />
      </div>
    </div>
    <div v-if="data?.preferredWorkFormat" class="form-client-application__format row">
      <div class="col-12">
        <ElementForm
          :data="data?.preferredWorkFormat"
          :activeValidate="activeValidate"
          @change="handleChange($event, 'preferredWorkFormat')"
          @update-validate="setValidate($event, 'preferredWorkFormat')"
        />
      </div>
    </div>
    <div v-if="data?.preferredConsultationTime" class="form-client-application__time row">
      <div class="col-12">
        <ElementForm
            :data="data?.preferredConsultationTime"
            :activeValidate="activeValidate"
            @change="handleChange($event, 'preferredConsultationTime')"
            @update-validate="setValidate($event, 'preferredConsultationTime')"
        />
      </div>
    </div>
    <div v-if="data?.whereFound" class="form-client-application__source row">
      <div class="col-12">
        <ElementForm
            :data="data?.whereFound"
            :activeValidate="activeValidate"
            @change="handleChange($event, 'whereFound')"
            @update-validate="setValidate($event, 'whereFound')"
        />
      </div>
    </div>

    <div class="form-client-application__footer row">
      <div class="col-12 col-md-6">
        * Поля обязательные для заполнения
      </div>
      <div class="col-12 col-md-6">
        <ButtonUI
          title="Оставить заявку"
          type="submit"
          @click="submitForm"
        />
      </div>
    </div>
  </form>
  <div v-else class="result-form-client-application">
    Спасибо, ваша заявка принята.
    Наш специалист свяжется с вами.
  </div>
</template>

<script>
import ElementForm from '../ui/Form/ElementForm'
import ButtonUI from '../ui/ButtonUI'
import { scrollToFirstElementWithError } from '../ui/Form/utils'

export default {
  name: 'FormClientApplication',
  emits: ['submit'],
  components: {
    ElementForm,
    ButtonUI
  },
  props: {
    data: {
      type: Object,
      default: () => null
    },
    isSent: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      activeValidate: false,
      model: {},
      validate: {}
    }
  },
  methods: {
    handleChange (value, name) {
      this.model[name] = value
    },
    setValidate (value, name) {
      if (value) {
        this.validate[name] = value
      } else if (this.validate[name]) {
        delete this.validate[name]
      }
    },
    submitForm ($event) {
      if ($event) {
        this.activeValidate = true

        if (Object.keys(this.validate)?.length === 0) {
          // Тогда отправляем форму
          this.$emit('submit', this.model)
        } else {
          scrollToFirstElementWithError(`[data-form-control="${Object.keys(this.validate)?.[0]}"]`)
        }
      }
    }
  }
};
</script>
